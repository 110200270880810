import React, { useState } from "react";

export default function Hero() {
  const [hoveredTab, setHoveredTab] = useState('Design');
  // const handleMouseLeave = () => {
  //   document.querySelector(`.howdosection`).remove("active");
  // };
return (
    <>
      <section className="section home-3-banner">
        <div className="base-container container">
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-lg-8 col-sm-12">
              <div className="home-3-banner-top-wrap">
                <div className="whatwedo">
                  <div className="do_hover">
                    <h2>What Perfect Web Services Offers?</h2>
                    <p>
                    The Perfect Web Services is a leading web solution company providing 
                    cutting-edge enterprise-level web services that assist businesses to 
                    succeed in the digital world. Whether it be a startup or an organization 
                    we have solutions for all your web and graphic designing services needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="numbers-wrapper-home-3" >
            <div className="numbers-wrap-vertical">
              <div
                onMouseEnter={() => setHoveredTab('Design')}
                
                className="service_heading border-bottom" >
                <h3 className="text-white">Designing</h3>
                <p>
                Dive into the world of top-notch and user-friendly designs with our 
                creative website design services. Get visually stunning design services
                enhancing the experience.
                </p>
              </div>

              <div
                className="service_heading border-bottom" 
                onMouseEnter={() => setHoveredTab('Frontend')}
                
                >
                 
                <h3 className="text-white">Development</h3>
                <p>
                Take your business to the next level with our 
                full stack development services. Leverage modern 
                frameworks to build your projects.
                </p>
              </div>

              <div
              className="service_heading" 
              onMouseEnter={() => setHoveredTab('Backend')}
                
              
              >
                 
                <h3 className="text-white">Marketing</h3>
                <p>
                We offer a range of effective marketing services to help 
                your business reach its target audience and achieve maximum growth.
                </p>
              </div>
            </div>
          </div>
          <div className="background-blue">
            <div href="#" className="home-3-video">
              <div className="content">
                
               
  <div className={`tabdo ${hoveredTab === 'Design' ? 'active' : ''}`} style={{ display: hoveredTab === 'Design' ? 'block' : 'none' }}>               

      <div className="vetical_main" >
                <h3>Design</h3>
                </div>
                  <div className="design_hover   yyyyyy">
                    <div className="border-bottom">
                      <h4>01. UI/UX Designing</h4>
                      <p>
                      We create innovative, intuitive and visually appealing UI/UX designs.
                      </p>
                    </div>
                    <br />
                    <h4>02. Logo Designing</h4>
                    <p>
                    Get a professional brand identity for your business with our logo designing services.
                    </p>
                  </div>
                 </div> 

                 <div className={`tabdo ${hoveredTab === 'Frontend' ? 'active' : ''}`} style={{ display: hoveredTab === 'Frontend' ? 'block' : 'none' }}>
                <div className="vetical_main ">
                          <h3>Development</h3>
                          </div>
                  <div className="design_hover">
                    <div className="border-bottom">
                      <h4>01. Frontend</h4>
                      <p>
                      We offer frontend development services that deliver robust and responsive web applications.
                      </p>
                    </div>
                    <br />
                    <h4>02. Backend</h4>
                    <p>
                    Our team of experts provides scalable, efficient and customized backend solutions.  
                    </p>
                  </div>
                  </div>
               

   

           <div className={`tabdo ${hoveredTab === 'Backend' ? 'active' : ''}`} style={{ display: hoveredTab === 'Backend' ? 'block' : 'none' }}>
          <div className="vetical_main ">
          <h3>Marketing</h3>
          </div>
                  <div className="design_hover  nnbb">
                    <div className="border-bottom">
                      <h4>01. SEO/SMO</h4>
                      <p>
                      Our SEO and SMO services will enhance your online presence and increase traffic to your website
                      </p>
                    </div>
                    <br />
                    <h4>02. Paid marketing</h4>
                    <p>
                    We create ROI-driven ads that bring measurable results and help you reach your target audience.
                    </p>
                  </div>
                  </div>
                

              </div>
            </div>
          </div>
        </div>
        <div className="absolute-back">
          <img
            src="/Assets/images/Vector.png"
            loading="lazy"
            width={295}
            height={295}
            alt="dots element"
            className="element-home-3-banner"
          />
           <img
            src="/Assets/images/Vector.png"
            loading="lazy"
            width={295}
            height={295}
            alt="dots element"
            className="element-home-4-banner"
          />
        </div>
      </section>
    </>
  );
}
