import React, { useState } from 'react';

export default function Rotate() {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <img
        src={"/Assets/images/Group.png"}
        className="rotate_img"
        alt="Slide 1"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
       <img
        src={"/Assets/images/Group2.png" }
        className="rotate_img"
        alt="Slide 1"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
      {/* <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" className='aero-right'>
	    <path d="M0 13.0001H25.5" stroke="#000;" strokeWidth="1.2"></path>
	    <path d="M18.8 5.80005L25.25 13L18.8 20.2" stroke="#000;" strokeWidth="1.2"></path>
      </svg> */}
        <img
        src={"/Assets/images/scale.png" }
        className="aero-right"
        alt="Slide 1"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
              <img
        src={"/Assets/images/up.png" }
        className="aero-right1"
        alt="Slide 1"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
    </>
  );
}
