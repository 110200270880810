import { Outlet } from "react-router-dom";
import Footer from "./Footer";

import React from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "./Header";




export default function Master(){
    return(
        <>
        <Header/>
        <Outlet/>
        <Footer/>
        <ToastContainer/>
        </>
    )
}