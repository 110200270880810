import Contact from "./Contact"
import AboutUs from "./AboutUs";
import Hero from "./Hero";
import Rotate from "./Rotateimg";
import Portfolio from "./Newportfolio";

export default function Home() {
  window.addEventListener('scroll', function() {
     
      const scrollPercentage = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 150;
      let newWidth = 100 - scrollPercentage * 5; 
      let newHeight = 100 - scrollPercentage * 5; 
      ///console.log(newWidth , " = ", newHeight);
      // Ensure newWidth and newHeight are capped at 42
      newWidth = Math.max(newWidth, 35);
      newHeight = Math.max(newHeight, 42);
      document.querySelector('.willchange').style.width = newWidth + '%';
      document.querySelector('.willchange').style.height = newHeight + '%';
  });
                                                             
     return (
    <>
      <section className="section" id="main-section">
      <div id="carouselExampleAutoplaying" className="carousel slide cst_slider nohieght willchange" data-bs-ride="carousel">
      <div className="roatet">
            <Rotate />
          </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="/Assets/images/043.webp" className="d-block w-100" alt="..." />
    </div>
    <div className="carousel-item">
      <img src="/Assets/images/044.webp" className="d-block w-100" alt="..." />
    </div>
    <div className="carousel-item">
      <img src="/Assets/images/045.webp" className="d-block w-100" alt="..." />
    </div>
  </div>
   </div>
        <div className="container roter">
          <div className="row">
            <div className="col-lg-9 col-md-8 mt-5 plateform">
              <h1 className="section_heading text-start text-white"style={{paddingTop:'0px'}}>
              Transform Your Business 360° With Our Web Services
              </h1>

               <p className="text-white we_build">
               Take a leap in this digital ocean and create a strong online presence for your business 
               with our comprehensive web services.
               </p>
               <div className="buttons d-flex cstm-center mt-3 mb-3">
          <a
            href="#contact_section"
            className="btn text-white"
            style={{
              border: "2px solid",
              borderRadius: "0px",
              fontFamily: "Montserrat",
              fontSize: "18px",
              fontWeight: "500",
              padding: "16px 47px",
            }}
          >
            Get A Quote
          </a>
        </div>
        <div className="buttons d-flex cstm-center mt-5 mb-5 mb-aero">
        <a  href="#portfolio_section" className="hero_arrow w-inline-block">
  <div className="tarrow_arrow">
    <div className="arrow_arrow-wrapper vertical">
      <div className="arrow_logo vertical w-embed">
        <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M24.0929 19.24L15.9996 27.3333L7.90625 19.24" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit={10}>
          </path>
          <path d="M16 4.66666V27.1067" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit={10} />
        </svg>
      </div>
      <div className="arrow_logo vertical w-embed">
        <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M24.0929 19.24L15.9996 27.3333L7.90625 19.24" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit={10} />
          <path d="M16 4.66666V27.1067" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit={10} />
        </svg>
      </div>
    </div>
  </div>
</a>
        </div>
            </div>
          </div>
        </div>
       </section> 
      <div id="what_we_do">
      <Hero/>
      </div>

      <div id="portfolio_section">
        { <Portfolio /> }
      </div>
      
      <div id="AboutUs_Section">
        <AboutUs />
      </div>
    <section id="all_end">
        <section className="business_section" id="business">
          <div className="container">
            <div className="row">
              <div className="col-md-12 ">
                <div className="inner text-center justify-content-center mx-auto">
                  <h2 className="text-white business_heading m-0">Let's Connect To Build</h2>
                  <h2 className="business_heading1 m-0">The Future Of Your Business</h2>
                  <p className="business_paragraph text-white">We are a dedicated team that works with the aim of developing lasting ties with our customers. Let’s come together over your business and see how we can help you accomplish your online goals. Call Perfect Web Services today to start building an active online presence.</p>
                </div>
              </div>
              <div className="col-md-6">
<img src="/Assets/images/Vector-dot.png" loading="lazy" width={295} height={295} alt="dots element" className="right-mg"/>

                </div>
                <div className="col-md-6">
<img src="/Assets/images/Vector-dot.png" loading="lazy" width={295} height={295} alt="dots element" className="left-mg" />

                </div>
            </div>
          </div>
        </section>
        <div id="contact_section">
          <Contact />
           </div> 
        <section className="img_section" id="business">
          <div className="row">
            <div className="col-md-12">
              <img src="/Assets/images/after_black.webp" alt="alt" />
            </div>
          </div>
        </section>
      </section>
      </>
  )
}


