import React,{ useState, useEffect }  from "react";
export default function Header() {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 50) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg">
      <div className={"container d-flex justify-content-between align-items-center HT-header " + (scroll ? "hide" : "show")}>
          <a className="navbar-brand text-white " href="/">
            <img
              src="/Assets/images/logo.png"
              alt="Logo"
              className="logo-img"
            />
          </a>

          <div className="d-lg-none ms-auto me-4">
            <a
              href="#top"
              className="navbar-icon bi-person smoothscroll text-white"
            ></a>
          </div>

          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav d-flex justify-content-between align-items-center">
              <li className="nav-item">
                <a className="nav-link click-scroll me-3 text-white" href="#what_we_do">
                  What We Do
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link click-scroll me-3 text-white" href="#portfolio_section">
                  Portfolio
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link click-scroll me-3 text-white" href="#AboutUs_Section">
                  About Us
                </a>
              </li>
            </ul>

            <div className="d-none d-lg-block">
              <a
                href="#top"
                className="navbar-icon bi-person smoothscroll text-white"
              ></a>
            </div>

            <div className="d-flex mr-5 justify-content-center">
            
            <a href="#contact_section" className="btn btn-square text-white cont-btn">
    Contact Us
  </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
