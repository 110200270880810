export default function AboutUs(){
return(
    <>
     <div className="About_section">
       <div className="container">
  <div className="row">
    <div className="col-lg-4 col-sm-12">
      <h2 className="about_headng1">
        About US
      </h2>
    </div>
    <div className="col-lg-8 col-sm-12">
    <h4 className="about_heading2 py-3"><span className="text-color1">We're a team</span> of passionate developers, designers, and marketers <span  className="text-color2">who are dedicated to helping businesses transform their online presence.</span></h4>

    <div className="row">
    <div className="col-md-6">
        <p className="about_text m-0">At Perfect Web Services, we reimagine digital perfection by creating custom-made websites that harmonize innovative designs with smooth functionality. With the help of our team of professionals and up-to-date technology, we will improve your website in such a way that it will look fantastic along with working perfectly.
    </p>
      </div>
      <div className="col-md-6">
        <p className="about_text m-0">Unleash the power of your brand with the help of Perfect Web Services. We realize the specific difficulties that businesses encounter on the web and provide individualized solutions that deliver interaction and conversions.
</p>
      </div>
    </div>
    </div>
  </div>
  </div>
</div>
    </>
)
}