import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Contact() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [websiteUrl, setWebsiteURL] = useState("");
  const [tellusaboutyourproject, setTellusaboutyourproject] = useState("");
  const [filterType, setFilterType] = useState([]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!firstName || !lastName || !emailAddress || !tellusaboutyourproject) {
      toast.error("All fields are required");
      return;
    }

    if (!isValidEmail(emailAddress)) {
      toast.error("Invalid email address");
      return;
    }
    const data = {
      firstName: firstName,
      lastName: lastName,
      emailAddress: emailAddress,
      websiteUrl: websiteUrl,
      tellusaboutyourproject: tellusaboutyourproject,
      filterType: filterType,
    };

    axios.post("https://pws.pssoftgroup.com/Apis/contactapi.php", data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((res) => {
      if (res.data.success) {
        toast.success(res.data.success);
      } else {
        toast.error("Form submission failed");
      }
    })
    .catch((err) => {
      toast.error("Something went wrong");
    });
  };

  const handleFilterTypeChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFilterType([...filterType, value]); // Add value to array
    } else {
      setFilterType(filterType.filter(item => item !== value)); // Remove value from array
    }
  };

  // Function to validate email address format
  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  return (
    <section className="contact_main">
      <div className="container contact-us-section">
        <form onSubmit={handleFormSubmit} id="contact_form">
          <div className="row justify-content-center text-center">
            <div className="col-md-12">
              <h2 className="contacts_heading text-white">
                Contact Information
              </h2>
              <ul className="event_filter">
                <li>
                  <input
                    id="checkbox1"
                    type="checkbox"
                    name="filter"
                    value="product_development"
                    onChange={handleFilterTypeChange} 
                    className="hidden-checkbox"
                
                  />
                  <label for="checkbox1" className="checkbox-button">
                 Product Development
                  </label>
                </li>

                <li>
                  <input
                    type="checkbox"
                    id="checkbox2"
                    name="filter"
                    value="ui/ux_design"
                    onChange={handleFilterTypeChange} 
                    className="hidden-checkbox"
                  />
                  <label for="checkbox2" className="checkbox-button">
                 
                    UI/UX Design
                  </label>
                </li>

                <li>
                  <input
                    type="checkbox"
                    id="checkbox3"
                    name="filter"
                    value="frontend"
                    onChange={handleFilterTypeChange} 
                    className="hidden-checkbox"
                  />
                  <label for="checkbox3" className="checkbox-button">
                    Frontend
                  </label>
                </li>
                 <li>
                  <input
                    type="checkbox"
                    id="checkbox4"
                    name="filter"
                    value="backend"
                    onChange={handleFilterTypeChange} 
                    className="hidden-checkbox"
                  />
                  <label for="checkbox4" className="checkbox-button">
                    Backend
                  </label>
                </li>
              </ul>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-5">
              <input
                type="text"
                className="form-control mb-2"
                id="firstName"
                name="First_Name"
                placeholder="First Name"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
              <input
                type="email"
                className="form-control mb-2"
                id="emailAddress"
                name="emailAddress"
                placeholder="Email Address"
                onChange={(e) => {
                  setEmailAddress(e.target.value);
                }}
              />
            </div>
            <div className="col-md-5">
              <input
                type="text"
                className="form-control mb-2"
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
              <input
                type="url"
                className="form-control mb-2"
                id="websiteURL"
                name="websiteUrl"
                placeholder="Website URL"
                onChange={(e) => {
                  setWebsiteURL(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-10 mb-2">
              <textarea
                className="form-control"
                id="projectDescription"
                name="tellusaboutyourproject"
                placeholder="Tell Us About Your Project"
                onChange={(e) => {
                  setTellusaboutyourproject(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="form_btn mt-5 d-flex justify-content-center">
            <button type="submit" className="btn btn-get-quote text-white">
              Get a quote
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}
