import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Carousel, Spinner } from "react-bootstrap";

export default function Newportfolio() {
  const [loading, setLoading] = useState(false);
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
  const [showSlider, setShowSlider] = useState(false);
  const [selectedTab, setSelectedTab] = useState("all");
  const [visibleImages, setVisibleImages] = useState(6);
  const [showMore, setShowMore] = useState(true);
  const [showHideButton, setShowHideButton] = useState(false);

  const handleAddPortfolio = async (type) => {
    try {
      setLoading(true);

      const url =
        type === "all"
          ? `https://pws.pssoftgroup.com/Apis/showportfolio.php?type=all&timestamp=${Date.now()}`
          : `https://pws.pssoftgroup.com/Apis/showportfolio.php?type=${type}&timestamp=${Date.now()}`;

      const response = await axios.get(url);
      if (response.data && response.data.portfolioItems) {
        setPortfolioItems(response.data.portfolioItems);
        setShowMore(response.data.portfolioItems.length > 6);
      } else {
        console.error("Error: Portfolio items not found in API response");
      }
    } catch (error) {
      console.error("Error fetching data:", error.response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleAddPortfolio(selectedTab);
  }, [selectedTab]);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setShowSlider(true);
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setVisibleImages(6);
    setShowMore(true);
    setShowHideButton(false); // Reset hide button visibility
    handleAddPortfolio(tab);
  };

  const handleShowMore = () => {
    setVisibleImages(visibleImages + 6);
    if (visibleImages + 6 >= portfolioItems.length) {
      setShowMore(false);
      setShowHideButton(true); // Show hide button when all images are displayed
    }
  };

  const handleHideImages = () => {
    setVisibleImages(6);
    setShowMore(true);
    setShowHideButton(false); // Hide button again when images are hidden
  };


  return (
    <>
      <div className="portfolio_Section">
        <div className="container">
          <div className="col-md-12">
            <h2 className="portfolio_heading m-0">
              OUR PORTFOLIO
            </h2>
          </div>
          <div className="port_filter">
            <ul className="tabs event_filter">
              <li>
                <a
                  onClick={() => handleTabClick("all")}
                  className={selectedTab === "all" ? "active" : ""}
                >
                  All work
                </a>
              </li>
              <li>
                <a
                  onClick={() => handleTabClick("wordpress_development")}
                  className={
                    selectedTab === "wordpress_development" ? "active" : ""
                  }
                >
                  Wordpress Development
                </a>
              </li>
              <li>
                <a
                  onClick={() => handleTabClick("web_development")}
                  className={selectedTab === "web_development" ? "active" : ""}
                >
                  Web Development
                </a>
              </li>
              <li>
                <a
                  onClick={() => handleTabClick("web_designing")}
                  className={selectedTab === "web_designing" ? "active" : ""}
                >
                  Web Designing
                </a>
              </li>
              <li>
                <a
                  onClick={() => handleTabClick("search_engine_optimization")}
                  className={
                    selectedTab === "search_engine_optimization" ? "active" : ""
                  }
                >
                  Search Engine Optimization
                </a>
              </li>
              <li>
                <a
                  onClick={() => handleTabClick("php_web_development")}
                  className={
                    selectedTab === "php_web_development" ? "active" : ""
                  }
                >
                  PHP Web Development
                </a>
              </li>
              <li>
                <a
                  onClick={() => handleTabClick("digital_marketing")}
                  className={
                    selectedTab === "digital_marketing" ? "active" : ""
                  }
                >
                  Digital Marketing
                </a>
              </li>
              
            
            </ul>
          </div>

          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          {portfolioItems.slice(0, visibleImages).map((item, index) => (
                 <div className={`col ${item.portfolio_type}`} key={item.id}>
                  <div className="main-col">
                    <div className="inner_col">
                      <span className="all_round">
                        <img
                          src="/Assets/images/circle.svg"
                          alt=""
                          className="svg-img"
                        />
                        <img
                          src="/Assets/images/circle.svg"
                          alt=""
                          className="svg-img"
                        />
                        <img
                          src="/Assets/images/circle.svg"
                          alt=""
                          className="svg-img"
                        />
                      </span>
                    </div>
                    <div className="card h-100 all_card" onClick={() => handleImageClick(index)}>
                    {item.images && item.images.length > 0 && (
                      <img src={"https://pws.pssoftgroup.com/admin/assets/uploads/" + item.images[0]} alt={`Portfolio ${index + 1}`} className="card-img-top all_img" />
                    )}
                  </div>
                  </div>
                </div>
              ))}
          </div>

          {showMore && (
              <div className="text-center mt-3">
                {loading ? (
                  <Spinner animation="border" />
                ) : (
                  <button
                    className="btn btn-square  port-btn"
                    onClick={handleShowMore}
                  >
                    Show More
                  </button>
                )}
              </div>
            )}

            {showHideButton && (
              <div className="text-center mt-3">
                <button
                  className="btn btn-square  port-btn"
                  onClick={handleHideImages}
                >
                  Hide
                </button>
              </div>
            )}
          </div>
        <PortfolioSlider
          show={showSlider}
          onHide={() => setShowSlider(false)}
          images={selectedImageIndex !== -1 ? portfolioItems[selectedImageIndex]?.images : []}
        />
      </div>
    </>
  );
}

function PortfolioSlider({ show, onHide, images }) {
  return (
    <Modal show={show} onHide={onHide} size="lg" centered className="portfolio-cr">
      <Modal.Body>
        <Carousel>
          {images && images.map((image, index) => (
            <Carousel.Item key={index}>
              <img src={"https://pws.pssoftgroup.com/admin/assets/uploads/" + image} className="d-block w-100" alt={`Portfolio ${index + 1}`} />
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal.Body>
    </Modal>
  );
}