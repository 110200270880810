import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Component/Home';
import Contact from './Component/Contact';
import Hero from './Component/Hero';
import AboutUs from './Component/AboutUs';
import Master from './Component/Layout/Master';
import Rotate from './Component/Rotateimg';
import Newportfolio from './Component/Newportfolio';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Master />}>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/hero" element={<Hero />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          {/* <Route path="/rotate" element={<Rotate />} /> */}
          <Route path="/newportfolio" element={<Newportfolio />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
