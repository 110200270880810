import React from "react";

export default function Footer() {
  return (
    <> 
    <div className="footer_section  text-light py-4 justify-content-center">
       <div className="container">
         <div className="row justify-content-center row_one">
           <div className="col-lg-4 col-md-12 col-sm-12 first_section justify-content-center">
           <a className="first_ancher" href="/">
      <img src="/Assets/images/logo.png" alt="Logo" className="footer_logo" />
            </a>
           </div>
           <div className="Contact_section col-lg-4 col-md-12 col-sm-12 text-center ">
             <h5 className="mb-3">Contact Us</h5>
             <p className="mb-2">+91-7508738945</p>
             <p>+91-7018158742</p>
             <p>contact@perfectwebservices.com</p>
           </div>
           <div className="col-lg-4 col-md-12 col-sm-12  text-center ">
             <h5 className="mb-3">Office</h5>
             <p className="left-cont"> E237AA, Phase 8B,Industrial Area, Sector74, Sahibjada Ajit Singh Nagar, Punjab 160055</p>
           </div>
       
         </div>
          
          <div className="row">
          <div className="Social_links col-md-12 text-center border-bottom">
            
            <ul className="list-inline ">
              <li className="list-inline-item me-4">
                <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FPerfectWebServ1" target="_blank" rel="noopener noreferrer">
                  Twitter
                </a>
              </li>
          
              <li className="list-inline-item me-4">
                <a href="https://in.linkedin.com/company/perfect-web-services" target="_blank" rel="noopener noreferrer">
                  Linkedin
                </a>
              </li>
         
              <li className="list-inline-item me-4">
                <a href="https://www.instagram.com/perfect_web_services/" target="_blank" rel="noopener noreferrer">
                  Instagram
                </a>
              </li>
             
              <li className="list-inline-item">
                <a href="https://www.facebook.com/perfectwebservices/" target="_blank" rel="noopener noreferrer">
                  Facebook
                </a>
              </li>
              </ul>
            </div>
          </div>
         <div className="row mt-3">
             <div className="col-md-6">
             <div className="text-start-cstm">
               <p>&copy; 2024 Perfect Web Services. All rights reserved.</p>
            </div>
             </div>
             <div className="col-md-6">
             <div className="text-end-cstm">
               <p>Privacy policy | Terms & Conditions</p>
             </div>
            </div>
        </div>
        </div>
    </div> 
</>
  );
}
